body {
  .nav-container {
    padding-bottom: 48px;
    margin-top: 17px;
    .auro-nav auro-icon {
      top: -5px !important;
    }
  }
  auro-dropdown#common {
    display: inline-block;
    width: 200px;
  }
  a.filter-buttons {
    display: inline-block;
    margin: 7px 20px 7px 0;
    text-decoration: underline;
    color: $baby-blue-color;
  }
  .mp-memberinfo-card {
    float: right;
    margin-bottom: 7px;
  }
  .mp-memberActivity-card {
    width: 100%;
    margin: 14px 0;
  }
  .mobile {
    display: none;
  }
  .zero-activity {
    font-size: 18px;
    font-weight: 450;
    font-family: "AS Circular";
    margin-bottom: 45px;
    margin-top: 24px;
  }
}

h2 {
  color: $blue-navy-color;
  display: inline-block;
  font-family: "AS Circular";
  font-size: 56px;
  font-style: normal;
  font-weight: 100;
  font-feature-settings: "liga" off;
  line-height: 68px;
  letter-spacing: -0.2px;
  white-space: nowrap;
}

h5 {
  margin-bottom: 24px;
  font-weight: 800;
}

table {
  font-weight: 100;
  td {
    vertical-align: top;
  }
  th {
    white-space: nowrap;
  }
}

//-------- media queries for mobile --------//
@media (max-width: 576px) {
  body {
    h2 {
      font-size: 44px;
      line-height: 54px;
    }
    
    .mobile {
      display: block;
    }
  }
}

//-------- media queries for tablet --------//
@media (min-width: 768px) {
  body {
    h2 {
      font-size: 48px;
      line-height: 60px;
    }
  }
}

//-------- media queries for desktop --------//
@media (min-width: 760px) {
  body {
    h2 {
      font-size: 48px;
      line-height: 60px;
    }
    *,
    *:before,
    *:after {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    @media (prefers-reduced-motion: reduce) {
      *,
      *:before,
      *:after {
        -webkit-animation: none !important;
        animation: none !important;
        -webkit-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
      }
    }
    *:focus {
      outline: 3px solid transparent;
    }
    .js-focus-visible :focus:not(.focus-visible) {
      outline: 3px solid transparent;
    }
    :host {
      display: block;
      overflow: scroll;
    }
    @media screen and (min-width: 660px) {
      :host {
        overflow: initial;
      }
    }
    table {
      overflow: scroll;
      width: 100%;
      border-spacing: 0.5rem;
      border-spacing: var(--auro-size-xs);
      margin-bottom: 2rem;
      margin-bottom: var(--auro-size-xl);
      font-weight: 100;
      font-weight: var(--auro-text-heading-display-weight);
    }
    table tr:nth-child(even) {
      background-color: $white-color;
      background-color: var(--auro-color-base-gray-100);
    }
    table th {
      text-align: left;
      font-weight: 500;
      font-weight: var(--auro-text-heading-default-weight);
    }
    table {
      border-collapse: collapse;
    }
    thead {
      border-bottom: 1px solid $gray-color;
      border-bottom: 1px solid var(--auro-color-base-gray-200);
      border-collapse: collapse;
    }
    th,
    td {
      padding: 1rem;
      padding: var(--auro-size-md);
    }
    .nowrap {
      white-space: nowrap;
    }
  }
}

//-------- media queries activites table when mobile --------//

@media only screen and (max-width: 760px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding: 7px 7px 7px 40%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
	Label the data
	*/
  td:nth-of-type(1):before {
    content: "Date";
    font-weight: 600;
  }
  td:nth-of-type(2):before {
    content: "Activity";
    font-weight: 600;
  }
  td:nth-of-type(3):before {
    content: "Status";
    font-weight: 600;
  }
  td:nth-of-type(4):before {
    content: "Points";
    font-weight: 600;
  }
  td:nth-of-type(5):before {
    content: "Bonus Points";
    font-weight: 600;
  }
  td:nth-of-type(6):before {
    content: "Total Points";
    font-weight: 600;
  }

  /* Zebra striping */
  tr:nth-of-type(odd) {
    background: var(--auro-color-base-gray-100);
  }

  tr {
    padding: 16px 0;
  }
  th {
    background: $black-color;
    color: $white-color;
    font-weight: bold;
  }
}
